import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
//ROUTER
  import router from './router'
//AXIOS
  import axios from 'axios'
  import VueAxios from 'vue-axios'
  Vue.use(VueAxios, axios)

Vue.config.productionTip = true

new Vue({
  router,
  vuetify,
  axios,
  render: h => h(App)
}).$mount('#app')
