<template>
    <v-container    >
      <v-data-table :headers="headers" :items="productos" class="elevation-1" >
        <template v-slot:items="props">
          <td>{{ props.item.CATEGORIA }}</td>
          <td>{{ props.item.SUBCATEGORIA }}</td>
          <td>{{ props.item.MARCA }}</td>
          <td>{{ props.item.CARACTERISTICAS }}</td>
          <td>{{ props.item.CATEGORIA }}</td>
          <td>{{ props.item.CODIGO }}</td>
          <!-- Agrega más columnas según los datos que devuelva tu API -->
        </template>
      </v-data-table>
    </v-container>
  </template>
  
  <script>
  
  import axios from "axios";

  export default {
    
    name: 'lista_productos',
    props: ['registro_empresa'],
    data() {
      return {
        headers: [
          { text: 'CATEGORIA', value: 'CATEGORIA' },
          { text: 'SUBCATEGORIA', value: 'SUBCATEGORIA' },
          { text: 'MARCA', value: 'MARCA' },
          { text: 'CARACTERISTICAS', value: 'CARACTERISTICAS' },
          { text: 'PRECIO', value: 'PRECIO' },
          { text: 'CODIGO', value: 'CODIGO' },
          // Agrega más encabezados de columna según los datos que devuelva tu API
        ],
        productos:[]
      }
    },
    methods:{
        async get_lista_productos_api() {
            const apiUrl = `http://control.simplesoft.com.ar/empresas/api_obtener_prod_movil.php?registro_empresa=${this.registro_empresa}`
            let datos = await axios.get(apiUrl)
            this.productos = datos.data
            console.log(this.registro_empresa)

            console.log(this.productos)
        },

        
    },

    created(){
        this.get_lista_productos_api()
    }
  }
  </script>