import Vue from 'vue'
//ROUTER
  import VueRouter from 'vue-router'
import principal_vista from '../views/principal_vista.vue'
import lista_productos_vista from '../views/lista_productos_vista.vue'

Vue.use(VueRouter)

const routes = [

  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/:registro_empresa',
    name: 'lista_productos_vista',
    component: lista_productos_vista,
    props: true
  },
  {
    path: '*',
    name: 'catchAll',
    component: principal_vista
 }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
