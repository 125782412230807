<template>
    <v-app>
       <principal/>
    </v-app>
</template>

<script>
  import principal from '../components/principal'

  export default {
    name: 'principal_router',

    components: {
      principal,
    },
  }
</script>
