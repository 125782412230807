<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
    <v-btn
        href="https://www.simplesoft.com.ar "
        target="_blank"
        text
      >
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="./assets/fav.png"
          transition="scale-transition"
          width="40"
        />
        <span class="mr-2">SIMPLESoft.TI - Distribuidora V&S</span>
      </div>
    </v-btn>

      <v-spacer></v-spacer>

    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
