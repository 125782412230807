<template>
    <v-app>
     <lista_productos :registro_empresa="registro_empresa_envio"/>
    </v-app>
</template>

<script>

  import lista_productos from '../components/lista_productos';
  
  //const datos = await axios.get(`http://control.simplesoft.com.ar/empresas/api_obtener_prod_movil.php?registro_empresa=${registroEmpresa}`);
    export default {
    name: 'lista_productos_vista',
   
    data() {
      return {
        registro_empresa_envio: this.$route.params.registro_empresa,
      }
    }, 

    components: {
      lista_productos,
    },

  };
  </script>
